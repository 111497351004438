import React  , { Component } from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'

import axios from "axios" 
import LazyLoad from 'react-lazyload';

import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer/Footer'
import {  AC_TOPPART, 
          AC_HomeSection, 
          AC_IMGAndText,
          AC_Container,
          AC_ROW,
          AC_Typography,
          AC_IMG,
          ResponsiveIframe,
          AC_Link,
          AC_Paragraph,
          AC_Logo3,
          AC_DatesBoxItem } from '../../Components/UI/UI';

import SimpleSlider from '../../Components/SimpleSlider/SimpleSlider';
import Form from '../../Components/Form/Form';
import { getPosts,
         isMobile,
         handleInputChange,
         regxValidation,
         simpleValidation } from '../../Utility/Utility';
import {datesData} from "./datesData"

import "./Dates.css"

const Ac_Divder = () => {
  return(
    <div className="ac-divder"></div>
  )
}


class Home extends Component {
  state = {
  
  }

  getDataFromServer = () => {
    
  }
  componentDidMount  = () =>  {
   
  }


  render(){

    const sliderSettings = {
      dots: false,
       autoplay: true,
      infinite: true,
    };

    
    const datesHTML = datesData.data.map( (o, i) => {
      return  <AC_DatesBoxItem title={o.title} desktopImg={o.img} text={o.text} color={o.color}/>
    })
   
 
    return(


      <div className="ac-page ac-page-dates">        
        <Menu />
        <AC_TOPPART desktopImg={'dates/main.jpg'} mobileImg={'mobile/2.jpg'} title={['', <br/> , '']} >
        </AC_TOPPART>
            

          <AC_HomeSection id="dates" isContainer={true}  cssClass={'ac-flex-wrap'}>
            <AC_Container>
              <AC_ROW>
                <div className="ac-text-wrapper">
                    <AC_ROW cssClass="ac-flex ac-justify-spacebetween ac-dates-header">
                      <AC_IMG desktopImg={'dates/datesofmial.png'}/>
                      <AC_IMG cssClass="ac-hide-on-mobile" desktopImg="dates/batsheva.png"/>
                    </AC_ROW>
                    <div className="p-wrapper">
                      <p class="bigger-p">
                          את הייצוא של חברת מיאל אימפקס <br className="ac-hide-on-mobile" />
                          מובילים גידולי הדגל של החברה תחת <br className="ac-hide-on-mobile" />
                          המותג "תמרי בת-שבע"
                      </p>
                      <p>
                          מבין תשעת סוגי התמרים הגדלים בישראל, תמרי מג'הול נחשבים לזן <br className="ac-hide-on-mobile" />
                          המשובח ביותר. תמרי המג'הול גדלים ברובם באזור דרום-מזרח ישראל, <br className="ac-hide-on-mobile" />
                          על גדות נהר הירדן, לצד הגבול בין ישראל לירדן. באזור זה זורמים מי <br className="ac-hide-on-mobile" />
                          נהר הירדן בקביעות והוא ניחן באקלים אופטימלי לגידול התמרים. <br className="ac-hide-on-mobile" />
                          גידול התמרים בתנאים אידיאלים אלו יוצרים תמרים גדולים, בעלי טעם <br className="ac-hide-on-mobile" />
                          נהדר ומראה מלכותי.
                      </p>
                      <p>
                          תמר המג'הול מזן "בת שבע" הוא תמר יוקרתי בצבע חום בהיר, על <br className="ac-hide-on-mobile" />
                          צורה מוארכת, טעם עסיסי, מתיקות, רעננות ויתרונות בריאותיים <br className="ac-hide-on-mobile" />
                          משמעותיים. תמר המג'הול, המכונה "מלך התמרים", נודע במשך שנים <br className="ac-hide-on-mobile" />
                          רבות בקרב השווקים האירופאים והים-תיכוניים כתמר ייחודי ומשובח, <br className="ac-hide-on-mobile" />
                          הגדל בישראל מאז ימי התנ"ך ועד היום. בחברת מיאל אימפקס <br className="ac-hide-on-mobile" />
                          ממשיכים את מסורת הגידול המפוארת עם תמרי "בת שבע" הניצבים <br className="ac-hide-on-mobile" />
                          בראש סולם האיכות של תעשיית התמרים הישראלית והעולמית.
                      </p>
                  </div>
                </div>
              </AC_ROW>
            </AC_Container>       
        </AC_HomeSection>

        <Ac_Divder />


        <AC_HomeSection id="production" isContainer={true}  cssClass={'ac-flex-wrap'}>
            <AC_Container>
              <AC_ROW cssClass='production'>
                  <AC_Typography elm="h2">
                    מסלול ייצור ואריזת התמרים
                  </AC_Typography>
                  <div className="ac-flex ac-justify-spacebetween ac-alignitems-center">
                    <div className="p-wrapper">
                        <p class="bigger-p">
                            כמוצר הדגל של מיאל, תמרי בת-שבע זוכים <br className="ac-hide-on-mobile" />
                            לליווי צמוד של החקלאים שלנו החל משלב <br className="ac-hide-on-mobile" />
                            ההאבקה ועד לגדיד התמרים המתרחש בחודשי <br className="ac-hide-on-mobile" />
                            אוגוסט עד אוקטובר.
                        </p>
                        <p>
                            בשלב הגדיד ייבחרו רק התמרים המובחרים והאיכותיים ביותר לייצוא <br className="ac-hide-on-mobile" />
                            מטעם מבקרי האיכות של החברה. הפרי מגיע היישר מן המטע לבתי <br className="ac-hide-on-mobile" />
                            אריזה חדשניים בכדי לשמור על איכות ורעננות מקסימלים. כל תמר <br className="ac-hide-on-mobile" />
                            נשקל בנפרד במכונת שקילה ייעודית, ממוין ונארז לפי דרישת הלקוח. <br className="ac-hide-on-mobile" />
                            התמרים מאוחסנים ונשלחים בטמפרטורת הקפאה ששומרת על <br className="ac-hide-on-mobile" />
                            סטנדרט הבריאות, הטריות והטעם האקזוטי שלהם.
                        </p>
                      </div>
                      <AC_IMG desktopImg={'dates/date-production.jpg'} cssClass={'dates-img'} />
                  </div>
              </AC_ROW>
            </AC_Container>       
      </AC_HomeSection>

        

        <AC_HomeSection id="our-products" cssClass={'ac-align-center ac-flex-wrap'}>
            <AC_ROW cssClass=''>
              <AC_Typography elm="h2">
                  המוצרים  שלנו
              </AC_Typography>
            </AC_ROW>
            <AC_ROW cssClass="ac-flex ac-justify-spacebetween ac-flex-wrap">
              <AC_DatesBoxItem title={datesData.data[0].title} desktopImg={datesData.data[0].img} text={datesData.data[0].text} color={datesData.data[0].color}/>
              <AC_DatesBoxItem title={datesData.data[1].title} desktopImg={datesData.data[1].img} text={datesData.data[1].text} color={datesData.data[1].color}/>
              <AC_DatesBoxItem title={datesData.data[2].title} desktopImg={datesData.data[2].img} text={datesData.data[2].text} color={datesData.data[2].color}/>
            </AC_ROW>
            <hr />

            <AC_ROW cssClass="ac-flex ac-flex-wrap">
              <AC_DatesBoxItem title={datesData.data[3].title} desktopImg={datesData.data[3].img} text={datesData.data[3].text} color={datesData.data[3].color}/>
              <AC_DatesBoxItem title={datesData.data[4].title} desktopImg={datesData.data[4].img} text={datesData.data[4].text} color={datesData.data[4].color}/>
            </AC_ROW>
            <hr />

            <AC_ROW cssClass="ac-flex ac-justify-spacebetween ac-flex-wrap">
              <AC_DatesBoxItem title={datesData.data[5].title} desktopImg={datesData.data[5].img} text={datesData.data[5].text} color={datesData.data[5].color} meta={datesData.data[5].meta}/>
              <AC_DatesBoxItem title={datesData.data[6].title} desktopImg={datesData.data[6].img} text={datesData.data[6].text} color={datesData.data[6].color} meta={datesData.data[6].meta}/>
              <AC_DatesBoxItem title={datesData.data[7].title} desktopImg={datesData.data[7].img} text={datesData.data[7].text} color={datesData.data[7].color} meta={datesData.data[7].meta}/>
            </AC_ROW>
            
            <AC_ROW cssClass="ac-flex nutritional">
                <p><b>Mejdoul Dates Nutritional Value</b></p>
                <p>Energy 283 Kcal</p>
                <p>Protein 1.6 g</p>
                <p>Carbohydrate 58 g</p>
                <p>Dietary Fibers 5.5 g</p>
                <p>Cholesterol 0 g</p>
                <p>Sodium 4 mg</p>
                <p>Iron 2 mg</p>
              </AC_ROW>
        </AC_HomeSection>


        <AC_HomeSection id="organic-agriculture" cssClass={'ac-flex-wrap'}>
            <AC_Container >
            <AC_ROW cssClass=''>
              
                <AC_Typography elm="h1">
                   חקלאות אורגנית <span>כדרך חיים</span>
                </AC_Typography>

                <AC_Typography>
                  החברה דוגלת בחקלאות אורגנית כדרך חיים.<br />
                  כיום עומדים לרשותנו טכנולוגיה וידע חקלאי המאפשרים גידול כמויות גדולות של תמרים<br className="ac-hide-on-mobile" />
                  ללא שימוש בחומרים כימיים שמשבשים את האיזון האקולוגי של כדור הארץ.<br/>
                  אנו מאמינים שחקלאות אורגנית היא הדרך לקיום אורח חיים בריא ולריפוי המערכת האקולוגית העולמית
                </AC_Typography>
                <AC_IMG desktopImg={'dates/date.jpg'} cssClass={'single-date-img'} />

            </AC_ROW>
           
            </AC_Container>
            
        </AC_HomeSection>

  

        <AC_HomeSection id="hakpada" cssClass={'ac-flex-wrap'}>
            <AC_Container >
            <AC_ROW cssClass='ac-flex ac-justify-spacebetween ac-alignitems-center'>
              
                <AC_Typography elm="h1">
                   הקפדה על תקני <br/>גידולים מקסימליים
                </AC_Typography>

                <AC_Typography>
                
                  החל משלב השתילה ועד לשלב האריזה, התמרים האורגניים שלנו מפוקחים בקפידה כדי<br className="ac-hide-on-mobile" />
                  לענות על תקני הגידול המחמירים על פי דרישות חוק משרד החקלאות הישראלית<br className="ac-hide-on-mobile" />
                  להסדרת תוצרת אורגנית (התשס"ה-2005). תקנים אלו מקבילים לתקנות 834/2007 של<br className="ac-hide-on-mobile" />
                  מועצת התקנים האירופאית.
                </AC_Typography>

           
            </AC_ROW>
           
            </AC_Container>
            
        </AC_HomeSection>
        <Ac_Divder />


        <LazyLoad height={200} once offset={400}>
          <Footer />
        </LazyLoad>
      </div>
    )
  }

}



export default withRouter(Home)