export const datesData = {

    title:'המוצרים שלנו',
    data:[
            {
                img:'dates/super-jumbo-lightblue.jpg',
                title:'סופר ג\'מבו - פרימיום',
                color: 'תכלת',
                text: ` משקל בגרמים: 26 +`
            },
            {
                img:'dates/jumbo-green.jpg',
                title:'ג\'מבו - פרימיום',
                color: 'ירוק',
                text: `משקל בגרמים: 23 - 26`
            },
            {
                img:'dates/large-beige.jpg',
                title: 'לארג\' - פרימיום',
                color: `בז'`,
                text: ` משקל בגרמים: 18 - 22`
            },
            {
                img:'dates/medium-red.jpg',
                title:'מדיום - פרימיום',
                color: 'אדום',
                text: `משקל בגרמים: 15 - 18`
            },
            {
                img:'dates/small-orange.jpg',
                title:'סמול - פרימיום',
                color: 'כתום',
                text: `משקל בגרמים: 10 - 15`
            },
            {
                img:'dates/jumbo-turquoise.jpg',
                title: 'ג\'מבו צ\'וייס - פרימיום',
                color: 'טורקיז',
                meta: "עד 25% עור מופרד",
                text: `משקל בגרמים: 23 - 26`
            },
            {
                img:'dates/large-yellow.jpg',
                title: 'לארג\' צ\'וייס',
                color: 'צהוב',
                meta: "עד 25% עור מופרד",
                text: `משקל בגרמים: 18 - 22`
            },
            {
                img:'dates/mediua-lightgreen.jpg',
                title: 'מדיום צ\'וייס',
                color: 'ירוק בהיר',
                meta: "עד 25% עור מופרד",
                text: `משקל בגרמים: 15 - 18`
            },
    ]
	
}