import React, { Component } from 'react';

import {  Input, Textarea  } from "../UI/UI";
import {  handleInputChange,
          regxValidation,
          simpleValidation } from '../../Utility/Utility';

import Http from '../../Axios/Axios'
import './Form.css';

class Form extends Component {
  state = {
    isFormSend:false,
    showErr:false,
    err:[],
    isRedirect:false,
    media: '',
    utm_term: '',
    utm_source: '',
    message: '',
    fields : [
        {
          name : 'fullname',
          type : "text" ,
          validationType:"simple",
          cssClass : "clean-input",
          placeholder : "שם",
          maxlength : '',
          id:"ac-fullname",
          val:"",
          err_1: "שם מלא הינו שדה חובה"
        },
         {
          name : 'phone',
          type : "tel" ,
          validationType:"phone",
          cssClass : "clean-input",
          placeholder : "טלפון",
          maxlength : '10',
          id:"ac-phone",
          val:"",
          err_1: "",
          err_2: "",
        },

        {
          name : 'email',
          type : "email" ,
          validationType:"email",
          cssClass : "clean-input",
          placeholder : "אי מייל",
          maxlength : '',
          id:"ac-email",
          val:"",
          err_1: "",
          err_2: "",
        },
       
        

    ]
  }

  constructor(props){
    super(props);
  }

  componentDidMount () {
  
  }

  cleanErrors = () => {
    this.setState({
      err:[]
    })
  }

  handleSubmit = (event) => {

    const queryString = window.location.search;
    const Campaign_url = window.location.href;
    console.log('queryString: ', queryString);
    console.log('Campaign_url: ', Campaign_url);
    const urlParams = new URLSearchParams(queryString);
    const media = urlParams.get('Media');
    const utm_term = urlParams.get('utm_term');
    const utm_source = urlParams.get('utm_source');

    let err = [];
    event.preventDefault();
    this.cleanErrors();



    const validFullName = simpleValidation({
      val : this.state.fields[0].val,
    });

    if ( validFullName !==true ) {
      err.push('יש להכניס שם מלא');
      alert('יש להכניס שם מלא');
    }

    const validPhone = regxValidation({
      val : this.state.fields[1].val,
      expression: /^[0-9]{10}$/
    });

    if ( validPhone !==true ) {
      err.push('יש להכניס מספר טלפון תקין');
      alert('יש להכניס מספר טלפון תקין');
    }

    const validEmail = regxValidation({
      val : this.state.fields[2].val,
      expression: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

    });

    if ( validEmail !==true ) {
      err.push('יש להכניס מייל תקין');
      alert('יש להכניס מייל תקין');
    }

    if ( err.length === 0) {
      /*
        do ajax this 
        this pass to the class via prop thanks to redux
        you can modify this action in /actions/login.actions.js
      */
        
        const data ={
              fullname: this.state.fields[0].val,
              phone:this.state.fields[1].val,
              email:this.state.fields[2].val,
              agree:this.state.agree,
              message: this.state.message,
              media:media,
              utm_term :utm_term,
              utm_source :utm_source
            };
            console.log('data.fullname: ', data.fullname);
            console.log('data.phone: ', data.phone);
            console.log('data.email: ', data.email);
            console.log('data.message: ', data.message);
            Http.post('api.php', data).then((res) => {
             console.log('thank you');
                  this.setState({
                        leadSend: true
                  })
          
          window.location.replace('https://mialimpex.com/ty.html?Media=' +  data.media  +  '&utm_source='  + data.utm_source  );
        })
        } else {
          this.setState({
            err:err
          })
        } 
    // window.location.href="/Search/"
    console.log('send');
    this.setState({
      isRedirect:true
    })
    
  }



  setFieldState ( name , value)  {
    const fields  = [...this.state.fields];
      // find the cureent field
      const field = fields.find( field => {
         return field.name === name
      });
      // console.log("field", field);
      // change the value 
      field.val = value;
      this.setState({ fields });
  }

  handleUserInput = ( event ) => {
    const target  = event.target; 
    const name    = target.name;
    const value   = target.value;
    this.setFieldState( name , value );
  }

  handleChange = (event) => {
    let o = handleInputChange(event);
    this.setState({
      [o.name] : o.value,
    });
  }


  render() {
    let showErrHTML = null;
    if ( this.state.showErr ) {
      showErrHTML = <span className="red">{this.state.fields[0].err_1}</span>
    }
    const formFields = this.state.fields.map((obj , index) => {
        return <Input key={index} type={obj.type}
                validationType={obj.validationType} 
                placeholder={obj.placeholder}
                name={obj.name} 
                val={obj.val}
                maxlength={obj.maxlength}
                hasToolTip={obj.hasToolTip}
                toolTipHTML={obj.toolTipHTML}
                inputCssClass={obj.cssClass}
                change={this.handleUserInput} /> 
        
    });



    let html = 
      <div className="ac-registration-form">
        <form  onSubmit={this.handleSubmit} className="ac-flex ac-flex-row">
          <div class="ac-contact-fields">
               { formFields }
          </div>
          <div class="ac-message-fields">
             <Textarea placeholder={"הודעה"}
                       change={this.handleChange}
                       val={this.state.message}
                       name={'message'}
                       cssInputClass={'ac-message'}/>
                   
          </div>
          <div class="ac-send-btn">
                <button type="submit">
                  שלח
                </button>
          </div>
          
            
          {showErrHTML}
        </form>
      </div>
      
    if ( this.state.isRedirect === true ) {
      {/*html = <Redirect to="/Search" />
            */}
      
    }
    return (
      html
    );
  }
}

export default Form;
