export const productsData = {

	legumes: {

		title:'קטניות',
		data:[
			{
				img:'legumes/1.jpg',
				title:'חומוס',
				text: `
					שמו המדעי של החומוס הוא "החימצה התרבותית" והיא אחת מהקטניות הראשונות ששימשו למאכל אדם! גרגרי החומוס עשירים בחלבון, ברזל, סיבים תזונתיים, סידן ומינרלים נוספים החיוניים לבריאות העצם, כמו גם חומצה פולית החיונית לבניית דנ"א וחומצות אמינו, מה שהופך אותם לבעלי חשיבות רבה לפני היריון ובמהלכו.`
			},
			{
				img:'legumes/2.jpg',
				title:'שעועית',
				text: `
					השעועית משתייכת למשפחת הקטניות וכוללת סוגים רבים של צמחים: שעועית לבנה, מנומרת, אדומה, שחורה, שעועית מש, בובס (לימה) והרשימה עוד ארוכה. השעועית היא מזון-על שמכיל כמות גדולה של ויטמינים, מינרלים ונוגדי חמצון החיוניים לגופנו ומספקים לו יתרונות בריאותיים.`
			},
			{
				img:'legumes/3.jpg',
				title:'עדשים (ירוקות / צהובות / אדומות)',
				text: `
				קטניה מזינה וטעימה העשירה בחלבונים, סידן, חומצה אמינית חיונית וויטמין A בצורת קארוטין, וויטמינים מקבוצה b ו-c. העדשים מתאימות מאוד למגוון מנות: מרקים, תבשילים, סלטים ואפילו פסטה.`
			}
		]
	},
	driedFruits:{
		title:'פירות יבשים ומסוכרים',
		data: [
			{
				img:'driedFruits/1.jpg',
				title:'משמשים',
				text: `
					המשמש המיובש עשיר באשלגן ובבטא קרוטן - פיטוכימיקל שממנו מקבל המשמש את צבעו הכתום. בנוסף לסיוע בהורדת כולסטרול, המשמשים יעילים מאוד בזמן עצירות, שכן יחס הסיבים לנפח בהם גבוה מהרגיל.


				`
			},
			{
				img:'driedFruits/2.jpg',
				title:'תאנים',
				text: `
					התאנה היא עץ ממשפחת התותיים. בארץ העץ גדל פרא ליד מעיינות, לאורך בקעת הירדן ובקעת ים המלח. תאנה מיובשת היא מקור מצוין למינרלים, ויטמינים ונוגדי חמצון. בנוסף, ב-5 תאנים יבשות (דבלים), נמצא כ-120 גרם סידן - יותר מאשר בגביע קוטג'.



				`
			},
			{
				img:'driedFruits/3.jpg',
				title:'צימוקים',
				text: `
					הצימוקים (ענבים מיובשים) קיימים אלפי שנים ומיוצרים במדינות בהן קיים גידול רב של גפנים. הצימוקים יכולים להוות תחליף מוצלח ובריא לחטיפי אנרגיה משום שהם מלאים בסוכר ומתוקים. את הצימוקים ניתן לאכול כפי שהם או לבשל ולאפות איתם.

				`
			},
			{
				img:'driedFruits/4.jpg',
				title:'שזיפים',
				text: `
				שזיפים מיובשים הם תרופת סבתא ותיקה וידועה נגד עצירות. בנוסף, מחקר חדש חשף שבנוסף להיותם עשירים באשלגן ונחושת, שזיפים מיובשים יכולים למנוע אובדן צפיפות עצם.



				`
			},
			{
				img:'driedFruits/5.jpg',
				title:'בננה צ\'יפס',
				text: `
					בננה היא צמח טרופי דמוי עץ שמקורו באזור הודו ומלזיה. ככל שהבננה בשלה יותר כך הופכים העמילנים שבה לסוכרים והיא נעשית מתוקה יותר. בנוסף לשימושיה הרבים למאכל, לבננה קיימים זנים רבים המשמשים גם לנוי.


				`
			},
			{
				img:'driedFruits/6.jpg',
				title:'חמוציות',
				text: `
					פרי שגדל על שיח נמוך ירוק עד שמקורו בביצות בצפון ארה"ב. התועלת העיקרית של החמוציות הינה לטיפול ומניעת דלקות בדרכי השתן. החמוציות משמשות כחומר מונע הצמדות חיידקים לתאי מערכת השתן ומונעות בכך הידבקות והתרבות חיידקים במערכת.



				`
			},
			{
				img:'driedFruits/7.jpg',
				title:'אוכמניות',
				text: `
				אוכמנית היא שיח הנפוץ ביערות אמריקה הצפונית ובמזרח אסיה. לאוכמניות, המכילות ריכוז גבוה של נוגדי חמצון, מספר סגולות המשמשות לרפואה התזונתית, ביניהן שיפור ראיית-לילה ומניעת הידרדרות היכולות הקוגניטיביות והזיכרון בתקופת הזקנה.




				`
			},
			{
				img:'driedFruits/8.jpg',
				title:'דובדבנים',
				text: `
					לדובדבן עצים גדולים וחסונים. עצי הפרי נפוצים באסיה, באירופה ובאמריקה הצפונית, אך 3 הארצות שמגדלות את הפרי באופן המסחרי ביותר הן ארצות הברית, איראן וטורקיה. הדובדבן מיובש בצורה טבעית ללא כל התערבות.



				`
			},
			{
				img:'driedFruits/9.jpg',
				title:'אננס מיובש טבעי',
				text: `
					אננס הוא פרי טרופי עשיר בויטמין A ו-C, הגדל באזור ברזיל, בוליביה ופרגוואי. ב-100 גר' של אננס מיובש יש כ-65 גר' פחמימות, והוא משמש כחטיף בריא לילדים ולמבוגרים.



				`
			},
			{
				img:'driedFruits/10.jpg',
				title:'גולדן ברי',
				text: `
					גולדן ברי הם תותי זהב בצבע כתום-זהוב והם בעלי טעם חמוץ מתוק ומאוד טעים. הגרגירים שלהם בעלי ערך תזונתי חיובי גבוה והם מלאים בסגולות בריאותיות ייחודיות. הגולדן ברי נקטפים ידנית בחוות גידול ביתיות בשיא בשלותם ומיובשים בטבעיות תחת השמש, באופן בו נשמרת בהם מספיק לחות ומוענקת להם טריות.



				`
			},
			{
				img:'driedFruits/11.jpg',
				title:'ג\'ינג\'ר דל סוכר',
				text: `
					הג'ינג'ר ידוע כצמח בעל מספר רב של סגולות רפואיות ייחודיות. תכונותיו האנטי דלקתיות מפחיתות כאב במפרקים ומעבירות בחילות. למעלה מ-30% מהייצור העולמי של הג'ינג'ר מתרחש בהודו, המדינה המובילה בתחום ייצור הג'ינג'ר בעולם.

				`
			},
			{
				img:'driedFruits/12.jpg',
				title:'מנגו טבעי',
				text: `
					המנגו הוא מין של עץ טרופי ואזור הגידול הטבעי שלו הוא באזורים הטרופיים של הודו וסין. בין סגולותיו העיקריות, קיים במנגו פוליפנול בשם מנגיפרין הנחשב לאחד מנוגדי החמצון החזקים ביותר שיש, המגנים על הנזק שנוצר מלחץ נפשי שפוגע בהרכב הדנ"א.


				`
			},
			{
				img:'driedFruits/13.jpg',
				title:'פפאיה טבעית',
				text: `
				מוצאה של הפפאיה מאמריקה התיכונה. בנוסף לטעמה המתוק והמעודן, לפפאיה סגולות בריאותיות רבות הנובעות משפע של חומרים מזינים, ואפשר להיעזר בה לטיפול במצבים בריאותיים רבים כמו בעיות עיכול, רמות הסוכר בדם, מחלות לב, פצעים, סרטן ועוד.


					
				`
			},
			{
				img:'driedFruits/14.jpg',
				title:'קיווי',
				text: `
					מקורו של הקיווי בדרום סין. כיום פרי זה גדל בכל העולם וגם בישראל. ב-100 גרם קיווי יש כ-100 מ"ג ויטמין C (כל כמות הוויטמין C המומלצת ליום.) בנוסף, הקיווי הוא מקור מצוין לאשלגן ומכיל חלבונים, ברזל, סידן, מגנזיום, זרחן, אבץ, סדרת ויטמין B, פוליסן וניאצין.


				`
			},
			{
				img:'driedFruits/15.png',
				title:'גוג\'י ברי ',
				text: `
					תותי גוג'י ברי הם תותים קטנים הנמנים תחת מזונות הסופר־פוד. שם הצמח הוא אדפטוגן, והוא מגביר את המרץ ועוזר לגוף להתמודד ביתר קלות עם מתח על ידי תמיכה בבלוטות יותרת הכליה. בנוסף מאט הגוג'י ברי את תסמיני ההזדקנות, ומשפר גם את הראייה וגם את התפקוד המיני.



				`
			}
			
			


		]
	},

	nuts:{
		title:'אגוזים',
		data:[
		    {
				img:'nuts/1.jpg',
				title:'אגוזי מלך',
				text: `
				אגוז המלך מכונה גם אגוז אנגלי או אגוז קליפורניה. אגוזי המלך גדלים בטבע מדרום סין ועד לבלקנים והם משמשים בעיקר לבישול ולקונדיטוריה כתוספת לעוגות ולתבשילים שונים. כיום מיובאים אגוזי המלך מארצות הברית ומצ'ילה.


					
				`
			},
			{
				img:'nuts/2.jpg',
				title:'קשיו',
				text: `
				מקור אגוזי הקשיו בעץ הקשיו. הם זקוקים למזג אוויר חם ולח על מנת לגדול וזו הסיבה לכך שמדינות כמו ניגריה, טנזניה, מוזמביק וברזיל מגדלות את הכמויות הגדולות ביותר בעולם. בנוסף, אגוזים אלה עשירים מאוד במינרלים: מגנזיום, אשלגן, מנגן, נחושת, סלניום, אבץ וברזל.


					
				`
			},
			{
				img:'nuts/3.jpg',
				title:'אגוזי פקאן',
				text: `
					הפקאן הוא עץ פרי נשיר שמקורו בדרום מזרח אמריקה הצפונית. עצי הפקאן מאריכים ימים ומניבים פירות עד גיל של יותר מ-300 שנה. חופן אגוזי פקאן יכול לעזור לשיפור הזיכרון ולמניעת הידרדרות קוגניטיבית. בנוסף לאכילתם כפי שהם, יכולים הפקאנים לשמש לבישול — במיוחד בקינוחים מתוקים אך גם בתבשילים מתובלים.


				`
			},
			{
				img:'nuts/4.jpg',
				title:'אגוזי ברזיל',
				text: `
				אגוז ברזיל הוא זן של עץ דרום אמריקאי יחיד מסוגו. הוא גדל במפוזר ביערות גשם על גדות נהרות האמזונס, הריו נגרו והאורינוקו. יתרונו הגדול של אגוז זה שהוא עשיר במינרלים כגון סלניום, מגנזיום, אשלגן וברזל. אגוזי ברזיל חשובים לחיזוק מערכת החיסון, שיפור רמת האנרגיה, שיפור מערכת העיכול, חיזוק כללי ועוד.
	
				`
			},
			{
				img:'nuts/5.jpg',
				title:'אגוזי מקדמיה',
				text: `
					אגוז המקדמיה גדל באופן טבעי במזרח אוסטרליה ובאינדונזיה. האגוזים מכילים 70% שומן ומהווים מקור לברזל, ויטמיןB1, זרחן וסידן. שמן אגוז המקדמיה נמצא בשימוש גם בתעשיית הקוסמטיקה בשל חומצות השומן החיוניות והייחודיות שיש בו, בעיקר אומגה 7, בעלת סיכויי חימצון נמוכים מאוד.


				`
			},
			{
				img:'nuts/6.jpg',
				title:'בוטנים קלופים',
				text: `
					אגוז אדמה, המכונה בוטן בשפה יומיומית, הוא זרעו האכיל של הצמח ארכיס תת-קרקעי. השם "בוטנים", ניתן לאגוז האדמה בטעות, מכיוון שבמקורות שם זה מציין את הפרי של האלה האמיתית (הפיסטוק). בבוטנים קיימים פיטוסטרולים העוזרים בהורדת כולסטרול, ויטמין E, סידן, מגנזיום, ויטמין B3 ואשלגן.`
			},
		
			{
				img:'nuts/7.jpg',
				title:'שקדים',
				text: `
					השקדים עשירים בחלבונים, סיבים, ויטמינים ומינרלים. אכילת שקדים נמצאה כתורמת לתחושת שובע לאורך זמן, משפרת את בריאות הלב ומאזנת את רמות הסוכר בדם. בנוסף, מחקרים מראים כי שקדים לא רק מורידים כולסטרול ומחזקים עצמות, הם גם עשויים לצמצם את היקפי המותניים והבטן.


				`
			},
			
			{
				img:'nuts/8.jpg',
				title:'פיסטוק חלבי',
				text: `
					פיסטוק הוא עץ המתנשא לגובה 10 מטרים. מוצאו מהאזורים ההרריים של איראן, טורקמניסטן ומערב אפגניסטן. אגוזי הפיסטוק ידועים ביכולות הריפוי הרבות שלהם – הם עשויים לעזור בשמירה על לב בריא, משקל גוף מאוזן, לתרום למניעת סוכרת ולחץ דם גבוה ולחזק את מערכת העיכול.


				`
			},
			{
				img:'nuts/10.jpg',
				title:'אגוזי לוז (בונדוק)',
				text: `
					את אגוזי הלוז מגדלים כיום לצרכים מסחריים בארצות שונות כגון תורכיה, ארה"ב, ספרד, אוסטרליה, איטליה, צרפת וסין. אגוזי הלוז עשירים בברזל, בסידן ובסיבים תזונתיים. ההרכב התזונתי המגוון של אגוזי לוז יכול לתרום רבות למצבי בריאות שונים, ביניהם הורדת כולסטרול, חיזוק המערכת החיסונית ושמירה על בריאות הלב.


				`
			}
		]
    },
    
    coffee:{
    		title:'קפה',
		    data:[
			    {
					img:'coffeeBeans/1.jpg',
					title:'פולי קפה מקולומביה, ברזיל, קוסטריקה, הודו וגוואטמאלה',
					text: `
						פולי קפה ערביקה גדלים באזורים הגבוהים בגובה של 900 עד 2000 מטר מעל לגובה פני הים, צורתם שטוחה אך ארוכה, וצבעם ירוק עמוק. הקפה רגיש לכפור, מה שמקשה על גידול פולי הקפה בגבהים הדרושים ולכן מייקר את שוויו. פולי קפה ערביקה נחשבים לזן משובח של קפה, בעיקר בזכות העומק הארומטי שלו והשפעת תכונות הקרקע ומזג האוויר על דקויות הטעם. 


					`
				},
		    ]
    },

    seeds:{
    		title:'זרעים',
		    data:[
			    {
					img:'seeds/1.jpg',
					title:'קינואה',
					text: `
					מקור הקינואה בהרי האנדים הגבוהים שבדרום אמריקה, שם שימשה כמאכל עממי שסיפק חלבון בסיסי לתושבי דרום אמריקה עוד בתקופת האינקה. הקינואה בעלת ערך תזונתי גבוה ביותר: היא עשירה בחלבון איכותי, סיבים תזונתיים, מגנזיום וברזל.

					`
				},
				{
					img:'seeds/2.jpg',
					title:'צ\'יה',
					text: `
						מקורם של זרעי הצ׳יה בצמח הסלביה היספניקה אשר גדל בעיקר בדרום אמריקה. הם מלאים בחומרים מזינים ובעלי השפעה חיובית על הגוף והמוח, ונחשבים בין המאכלים הבריאים בעולם. זרעי הצ'יה מכילים חומרים מזינים רבים אך מעט קלוריות.`
				},
				{
					img:'seeds/3.jpg',
					title:'פרג',
					text: `
						לזרעי הפרג שימושים רבים: הסמכה, תיבול, עיבוי או קישוט בתבשילים, מאפים ומוצרי מזון שונים. הם מכילים רמות גבוהות של שומן, סידן, וויטמינים מקבוצה B והם בעלי ערך תזונתי גבוה. בנוסף לשימושיו במטבח, שימש הפרג בעבר גם למאור ולגימור עץ, ואפילו להכנת צבעים וסבונים.


					`
				},
				{
					img:'seeds/4.jpg',
					title:'צנובר',
					text: `
						הצנובר הוא הזרע של עץ האורן. קיימים בסך הכול כעשרים סוגים שונים של עצי אורן שהצנוברים שלהם מספיק גדולים כדי לשמש לצרכי מזון. הצנוברים הנפוצים במזרח התיכון, שצורתם מאורכת, מכילים למעלה משלושים אחוז חלבון - תכולת החלבון הגבוהה ביותר מכל זני האגוזים הקיימים בעולם.`
				},
				{
					img:'seeds/5.jpg',
					title:'שומשום',
					text: `
						השומשום הוא צמח חד-שנתי הגדל בעיקר בבורמה, הודו, סין ואתיופיה. צמח השומשום עמיד מאוד לתנאי יובש ובצורת ומסוגל לגדול בתנאים בהם גידולים אחרים לא יכולים להחזיק מעמד. מסיבה זו הוא נפוץ במקומות חמים ויבשים. בנוסף להיותו מקור לחלבון, סידן, ושומן בריא, השומשום מסייע גם בהקלת כאבים, מרגיע ומאריך את תחושת השובע.


					`
				},
				{
					img:'seeds/6.jpg',
					title:'גרעיני דלעת',
					text: `גרעיני דלעת מופקים מפרי הדלעת ששייך לקבוצת הדלועיים, אליהם שייכים גם המלפפון והקישוא. גרעיני הדלעת עשירים מאוד בוויטמינים ומינרלים, ובמיוחד באבץ - מינרל חשוב מאוד לתהליכים רבים בגוף כגון סיוע לשיפור הפריון, חיזוק און גברי, שיפור אנרגיה ומצב רוח ועוד. בנוסף, גרעיני הדלעת טובים גם לחיזוק המערכת החיסונית.

						
					`
				},
				{
					img:'seeds/7.jpg',
					title:'גרעיני חמנייה',
					text: `
						גרעיני חמנייה, המכונים גם גרעינים שחורים, משמשים למאכל ולהפקת שמן. בשימושם למטרות מסחריות נהוג לסווג אותם על פי קליפתם: קליפה בצבע שחור חלק מצביעה לרוב על זרעים המשמשים להפקת שמן, וזרעי חמניות עם פסים עליהם משמשים בעיקר למאכל.

					`
				},
				{
					img:'seeds/8.png',
					title:'שום',
					text: `
						פקעת ממשפחת הנרקיסיים. השום ידוע בתור "האנטיביוטיקה של הטבע". השום הורג מספר רב של חיידקים שונים, ולכן נפוץ מאוד כתרופת סבתא עממית. בנוסף, השום מחזק את המערכת החיסונית, מונע קרישי דם ואפילו עוזר לנשים בגיל הבלות בהגברת ייצור אסטרוגן.


					`
				},
				{
					img:'seeds/9.png',
					title:'גרעיני אבטיח ',
					text: `
						גרעיני האבטיח הם מהפיצוחים הנפוצים ביותר כיום. בארץ מפיקים את הגרעינים מאבטיח מללי והם מהווים מקור טוב למגנזיום, זרחן, אבץ ומנגן. בנוסף, גרעיני אבטיח מכילים כמות מכובדת של ברזל, ועשירים בויטמין D.


					`
				}
				
		    ]
    },

    spices: {

		title:'תבלינים',
		data:[

		    {
				img:'spices/1.jpg',
				title:'פלפל שחור',
				text: `
					הפלפל השחור הוא צמח מטפס ממשפחת הפלפליים. פירותיו עוברים ייבוש בשמש או באמצעים תעשייתיים ומשמשים לבסוף כתבלין. שמו של החומר הפעיל המצוי בפלפל הוא פיפרין. הוא מסייע להפחתת כאבים ויכול לתרום לצמצום דלקות. בנוסף, התבלין מכיל ויטמינים וחומרים טבעיים שונים המועילים לתקינות מערכות הגוף.


				`
			},
			{
				img:'spices/2.jpg',
				title:'פלפל צ\'ילי ושאטה',
				text: `
					פלפל צ'ילי, או בשמו המדעי פלפלת צ'ילי, הוא שם כולל למספר מינים של פלפלת. הוא בוית באמריקה הצפונית עוד לפני 6,000 שנה. מספרים כי כריסטופר קולומבוס היה האדם האירופאי הראשון שנתקל בהם כשביקר בקריביים, והוא נתן להם את הכינוי פלפל, ככל הנראה בגלל הדמיון בטעם בינם לבין הפלפל השחור שהיה מוכר כבר אז למטבח האירופי.
`
			},
			{
				img:'spices/3.jpg',
				title:'כמון',
				text: `
					כמון הוא צמח תבלין חד-שנתי ממשפחת הסוככיים. התפוצה הטבעית שלו היא מהים התיכון ועד מזרח הודו, אך יש שאומרים שמקורו של הכמון בסוריה, סרי לנקה, פקיסטן וטורקיה. זרעי הכמון בעלי השפעה חיובית על הגוף ועשויים לתרום להקלה על כאבי שיניים וגזים ולטיפול במצבי הצטננות ושיעול.
`
			},
			{
				img:'spices/4.jpg',
				title:'ג\'ינג\'ר',
				text: `
				הג'ינג'ר ידוע כצמח בעל מספר רב של סגולות רפואיות ייחודיות. בין היתר, הוא עוזר למיצי העיכול ומשפר את ספיגת הויטמינים בגוף. תכונותיו האנטי דלקתיות מפחיתות כאב במפרקים ומעבירות בחילות. למעלה מ-30% מהייצור העולמי של הג'ינג'ר מתרחש בהודו, המדינה המובילה בתחום ייצור הג'ינג'ר בעולם.



				`
			},
			{
				img:'spices/5.jpg',
				title:'סומק',
				text: `
				הסומק הוא תבלין המעניק טעם עשיר למנות רבות ומגוונות במטבחים ברחבי העולם. מעבר לכך, לתבלין הסומק יתרונות בריאותיים רבים, הנובעים מריכוז גבוה של נוגדי חמצון, פלבונואידים, פוליפנולים וחומרים שונים שהוא מכיל.

				`
			},
			{
				img:'spices/6.jpg',
				title:'קינמון',
				text: `
				הקינמון הוא תבלין המופק מקליפתם הפנימית של כמה מינים שונים של עצי הקינמונום. הוא משמש בעיקר לתיבול עוגות, קינוחים ובשר. בנוסף, לקינמון שימושים בריאותיים רבים - הוא מועיל נגד השמנה, מאזן את הסוכרת ומוריד כולסטרול.

				`
			}
			
			
			

		]
	},

}