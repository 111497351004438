import React  , { Component } from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'

import axios from "axios" 
import LazyLoad from 'react-lazyload';

import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer/Footer'
import {  AC_TOPPART, 
          AC_HomeSection, 
          AC_IMGAndText,
          AC_Container,
          AC_ROW,
          AC_Typography,
          AC_IMG,
          ResponsiveIframe,
          AC_Link,
          AC_Paragraph,
          AC_Logo3 } from '../../Components/UI/UI';

import SimpleSlider from '../../Components/SimpleSlider/SimpleSlider';
import Form from '../../Components/Form/Form';
import { getPosts,
         isMobile,
         handleInputChange,
         regxValidation,
         simpleValidation } from '../../Utility/Utility';
import {Link } from "react-router-dom";

import "./Home.css"

const Ac_Divder = () => {
  return(
    <div className="ac-divder"></div>
  )
}


class Home extends Component {
  state = {
  
  }

  getDataFromServer = () => {
    
  }
  componentDidMount  = () =>  {
   
  }


  render(){

    const sliderSettings = {
      dots: false,
       autoplay: true,
      infinite: true,
    };
   
 
    return(


      <div className="ac-page ac-page-home">        
        <Menu />
          {/*Slider*/}
          <SimpleSlider>
            <AC_TOPPART  cssClass={'ac-home-slide-1'} desktopImg={'1.jpg'} mobileImg={'mobile/1.jpg'} title={['מהשדה ועד אליכם!', <br/> , 'מיאל אימפקס מתמחה בייבוא וייצוא חומרי גלם וגידולים חקלאיים טריים מאז 1986.']} >

            </AC_TOPPART>

            <AC_TOPPART cssClass={'ac-home-slide-2'} desktopImg={'2.jpg'} mobileImg={'mobile/2.jpg'} title={['טעם שאי אפשר לשכוח:', <br/> , 'הגידולים שלנו משובחים במיוחד בזכות שימוש בטכנולוגיות חקלאיות חדשניות.']} >
             
            </AC_TOPPART>
            
            { 
            <AC_TOPPART cssClass={'ac-home-slide-3'} desktopImg={'3.jpg'} mobileImg={'3.jpg'}  title={['למיאל בתי אריזה חדשניים ומתקדמים בהם ממוינים, מקוררים ונארזים המוצרים בדרך לשיווק, תוך עמידה בתנאי התקן הנדרשים.']} >
            <Ac_Divder />
             
            ככה עושים את זה<span className="ac-hide-on-mobile">!</span><span className="ac-show-on-mobile">:</span>
            </AC_TOPPART>
            }
            
          </SimpleSlider>

        

        <AC_HomeSection id="products" cssClass={'ac-align-center ac-flex-wrap'}>
            <AC_ROW cssClass=''>
              <AC_Typography elm="h2">
                  המוצרים  שלנו
              </AC_Typography>
            </AC_ROW>
            <AC_ROW cssClass="ac-flex ac-justify-spacebetween ac-flex-wrap">
              <AC_IMGAndText desktopImg={'products/1.jpg'} title={'קטניות'} link={'/Products/Product/legumes'}>
              </AC_IMGAndText>
              <AC_IMGAndText desktopImg={'products/2.jpg'} title={'זרעים'} link={'/Products/Product/seeds'}>
              </AC_IMGAndText>
              <AC_IMGAndText desktopImg={'products/3.jpg'} title={'אגוזים'} link={'/Products/Product/nuts'}>
              </AC_IMGAndText>
              <AC_IMGAndText desktopImg={'products/4.jpg'} title={'תבלינים'} link={'/Products/Product/spices'}>
              </AC_IMGAndText>
              <AC_IMGAndText desktopImg={'products/5.jpg'} title={'קפה'} link={'/Products/Product/coffee'}>
              </AC_IMGAndText>
              <AC_IMGAndText desktopImg={'products/6.jpg'} title={'פירות יבשים ומסוכרים'} link={'/Products/Product/driedFruits'}>
              </AC_IMGAndText>
            </AC_ROW>
            
        </AC_HomeSection>

        <AC_HomeSection id="exports" isContainer={true}  cssClass={'ac-flex-wrap'}>
            <AC_Container>
              <AC_ROW cssClass=''>
                <div className="ac-text-wrapper">
                  <div className="ac-title-wrapper">
                    <AC_Typography elm="h2">
                        מייבאים <br/> 
                        ומייצאים לכל
                    </AC_Typography>
                  </div>
                  <Ac_Divder />
                  <AC_Typography >
               
               שירותי הייבוא והייצוא של מיאל חוצי יבשות. ארה"ב, הודו, רוסיה, טורקיה, איטליה וברזיל הן רק חלק קטן מהמדינות איתן אנחנו עובדים, החל משנת 1976.


                  </AC_Typography>
                </div>
              </AC_ROW>
            </AC_Container>

            {<AC_IMG desktopImg={'map.gif'} cssClass={'ac-map'} />}
           
            
        </AC_HomeSection>

        <AC_HomeSection id="guidlines" isContainer={true}  cssClass={'ac-align-center ac-flex-wrap'}>
            <AC_ROW cssClass=''>
               <div className="ac-title-wrapper">
                <AC_Typography elm="h2">
                    המטרות והערכים המובילים שלנו
                </AC_Typography>
              </div>
            </AC_ROW>

            <Ac_Divder />
            <AC_ROW cssClass="ac-flex ac-justify-spacebetween ac-icons-wrapper">
              <AC_IMGAndText desktopImg={'icons/1.gif'} title={'הקפדה על איכות המוצר:'}>
                אנחנו מייבאים מוצרים איכותיים בגידולים<br/>
                 בכל רחבי העולם ושומרים עליהם<br/>
                  בתנאים מקסימליים.
              </AC_IMGAndText>

              <AC_IMGAndText desktopImg={'icons/2.gif'} title={'השקעה וחדשנות:'}>
               כדי לייצר גידולים חקלאיים מדהימים<br/>
                אנחנו מיישמים באופן תמידי <br/>
                חידושים בתחום. 
              </AC_IMGAndText>

              <AC_IMGAndText desktopImg={'icons/3.gif'} title={'אוהבים את הלקוחות שלנו:'}>
               שירות אמין, דיאלוג מתמיד ויחס <br/>
               אישי לכל לקוח הם חובה אצלנו! 
              </AC_IMGAndText>

              <AC_IMGAndText desktopImg={'icons/4.gif'} title={'בקרת איכות:'}>
               המוצרים שלנו עומדים בסטנדרטים <br/>
               הגבוהים ביותר של בקרות<br/>
                האיכות השונות.
              </AC_IMGAndText>
            </AC_ROW>
            
        </AC_HomeSection>

        
        <AC_HomeSection id="dates" cssClass={'ac-flex-wrap'}>
            <AC_Container >
            <AC_ROW cssClass='ac-flex ac-justify-spaceevenly'>
              
              <div className="ac-text-wrapper ac-align-center">
                  <Link to="/Dates" className="ac-dates-link" aria-label="התמרים שלנו">
                    <AC_IMG desktopImg={'title.gif'} cssClass={'ac-img-title'} />
                  </Link>

              <Ac_Divder />
                <AC_Typography>
                <strong>

                    גידול תמרי "בת שבע" האהובים מתבסס על<br className="ac-hide-on-mobile"/> חדשנות טכנולוגית ושיטות ניהול מתקדמות.

                </strong>
                </AC_Typography>

                <AC_Typography>
                  מטעי התמרים מזן "בת שבע" מלווים את החברה שלנו במשך שנים ואנחנו מגדלים, אורזים ומשווקים אותם בישראל ובעולם. פרי עץ התמר גדל בדרך כלל באזורים טרופיים ויש לו יתרונות בריאותיים משמעותיים. התמרים עשירים בויטמינים ובמינרלים הנחוצים מאוד לגוף האדם. בתמר תמצאו סידן, זרחן, מגנזיום ותכונות רבות המסייעות לבריאות תקינה.


                </AC_Typography>
              </div>

              <Link to="/Dates" className="ac-dates-link" aria-label="map">

                <AC_IMG desktopImg={'Group 65.jpg'} cssClass={''} />

              </Link>
          
            </AC_ROW>
          
            </AC_Container>
            
        </AC_HomeSection>



        <AC_HomeSection id="local-mob" cssClass={'ac-flex-wrap'}>
         <AC_IMG mobileImg={'tree.png'} cssClass={''} />
        </AC_HomeSection>

        <AC_HomeSection id="local" cssClass={'ac-flex-wrap'}>
            <AC_Container >
            <AC_ROW cssClass='ac-flex'>
              
              <div className="ac-text-wrapper">
                <AC_Typography elm="h1">
                   גידולים מקומיים
                </AC_Typography>

               <Ac_Divder />
                <AC_Typography>
                 <strong>
                   
                    בנוסף לתמרים, אנחנו משקיעים בגידולים מקומיים&nbsp;<br/>
                    נוספים המבוססים על השקעה וחדשנות טכנולוגיות: 

                 </strong>
                </AC_Typography>

                <AC_Typography>
                
                  גרעיני אבטיח, חימצה, גרעיני חמניות ושקדים. הגידולים <br/>
                  מיוצאים למדינות רבות בארה"ב ובאיחוד האירופי.
                </AC_Typography>
              </div>

            
             
           
            </AC_ROW>
           
            </AC_Container>
            
        </AC_HomeSection>

        <AC_HomeSection id="about-us" cssClass={'ac-flex-wrap ac-reset-padding'}>
            <AC_Container >
            <AC_ROW cssClass='ac-flex'>
              
              <div className="ac-text-wrapper">
                <AC_Typography elm="h2">
                    הסיפור שלנו
                </AC_Typography>

              
                <AC_Typography>
                 <strong>
                  הכל החל מדוכן קטן שנוסד ב-1976 בשוק לוינסקי למכירת אגוזים, תבלינים ופירות יבשים. בזכות עבודה מסורה, שירות אמין וסחורה נהדרת הלקוחות שמרו לנו אמונים, וצמחנו לחברת ייבוא וייצוא פרטית ייחודית שפועלת בכל רחבי העולם ומונהגת על ידי משפחתנו במשך שלושה דורות.

                 </strong>
                </AC_Typography>

                <AC_Typography>
                   
                 אנחנו במיאל אימפקס מתמחים בייבוא חובק עולם של פיצוחים, אגוזים ופירות יבשים ובייצוא ענף של תמרים, גרעיני חמניות וגרעיני אבטיח למדינות האיחוד האירופי וארצות הברית.


                </AC_Typography>
              </div>

              <div className="ac-about-us-slider">
              <SimpleSlider settings={sliderSettings}>
                  <AC_IMG desktopImg={'/slider/1.jpg'} mobileImg={'/slider/mob/1.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/2.jpg'} mobileImg={'/slider/mob/2.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/3.jpg'} mobileImg={'/slider/mob/3.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/4.jpg'} mobileImg={'/slider/mob/4.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/5.jpg'} mobileImg={'/slider/mob/5.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/6.jpg'} mobileImg={'/slider/mob/6.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/7.jpg'} mobileImg={'/slider/mob/7.jpg'}></AC_IMG>
                  <AC_IMG desktopImg={'/slider/8.jpg'} mobileImg={'/slider/mob/8.jpg'}></AC_IMG>
                  
              </SimpleSlider>
              </div>
            </AC_ROW>
            </AC_Container>
        </AC_HomeSection>

        <AC_HomeSection id="about-us-mob" cssClass={'ac-flex-wrap ac-reset-padding'}>
        <div className="ac-div-box-container"></div>
        </AC_HomeSection>

        <AC_HomeSection id="contact" cssClass={'ac-flex-wrap ac-reset-padding'}>
            <AC_Container >

              <AC_ROW cssClass='ac-flex'>
                  <AC_Typography elm="h2">
                      צור קשר
                  </AC_Typography>
              </AC_ROW>    

              <AC_ROW cssClass='ac-flex'>
               <Form />
              </AC_ROW>
             
            </AC_Container>
            
        </AC_HomeSection>

        <AC_HomeSection id="map" cssClass={'ac-flex-wrap ac-reset-padding'}>
            <AC_Container >
            <AC_ROW cssClass='ac-flex'>
              <ResponsiveIframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3383.840502781928!2d34.77115251507538!3d31.99233893088549!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1502b3806d9b4337%3A0xe71c20f75a1e9a12!2sLazarov%20St%2025%2C%20Rishon%20LeTsiyon!5e0!3m2!1sen!2sil!4v1579511991673!5m2!1sen!2sil" />
            </AC_ROW>
           
            </AC_Container>
            
        </AC_HomeSection>

        <AC_HomeSection id="adress" cssClass={'ac-flex-wrap ac-reset-padding'}>
            <AC_Container >
               <AC_ROW cssClass='ac-flex ac-flex-row'>
                    <div class="ac-wrapper-1">
                      <AC_Paragraph text="משרדים" className="ac-text"/>
                      <div class="ac-flex ac-flex-row ac-flex-row-mob">
                           <AC_Paragraph text="לזרוב 25 ראשון לציון מיקוד 7565431 "/>
                           <AC_Paragraph text="  |  " className="ac-vertical-line ac-hide-on-mobile"/>
                           <AC_Link href="tel:039416010" className="ac-num-1" text="טל: 03-9416010"/>
                      </div>
                    </div>
                    <div class="ac-wrapper-2">
                      <AC_Paragraph text="מחסן" className="ac-text"/>
                      <div class="ac-flex ac-flex-row ac-flex-row-mob">
                           <AC_Paragraph text="צור יגאל הבזלת 12 " />
                           <AC_Paragraph text=" | " className="ac-vertical-line"/>
                           <AC_Link href="tel:097681726" className="ac-num-2" text="טל: 09-7681726" />
                      </div>
                    </div>
                    <div class="ac-wrapper-3">
                      <AC_Paragraph text="פרטים כללים" className="ac-text"/>
                      <div class="ac-flex ac-flex-row ac-flex-row-mob">
                           <AC_Link href="tel:039416010" className="ac-num-3" text=" 03-9416010 "/>
                           <AC_Paragraph text="  |  " className="ac-vertical-line"/>
                           <AC_Link href="mailto:mazal@mialimpex.com?Subject=Hello%20again" className="ac-mail" text="mazal@mialimpex.com"/>
                      </div>
                    </div>
                    <div class="ac-wrapper-4 ac-flex ac-justify-flex-end">
                       <AC_Logo3 />
                    </div>
               </AC_ROW>
            </AC_Container>
        </AC_HomeSection>

        <LazyLoad height={200} once offset={400}>
          <Footer />
        </LazyLoad>
      </div>
    )
  }

}



export default withRouter(Home)