//  ie 9 support 
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';

import App from './App';
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';
import axios from 'axios';


import "./fonts/fonts.css";
import './media.css';
require('es6-promise').polyfill();
const basename = "/";

ReactDOM.render(
<BrowserRouter basename={basename}  >
	<App />
</BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
