
// note 
// npm install react-slick
// npm install slick-carousel
// https://github.com/akiran/react-slick

/**
  use 

  import SimpleSlider from './components/SimpleSlider/SimpleSlider';
  
 <SimpleSlider settings={customSliderSettings}>
      <div><h3>Hey Man 1</h3></div>
      <div><h3>Hey Man 2</h3></div>
      <div><h3>Hey Man 3</h3></div>
      <div><h3>Hey Man 4 </h3></div>
      <div><h3>Hey Man 5 </h3></div>
      <div><h3>Hey Man  6 </h3></div>
       </SimpleSlider>
*/
import React, { Component } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SimpleSlider.css";

import { AC_IMG } from '../UI/UI';



function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' ac-custom-image-slider-wrapper'}
      onClick={onClick}>

      <AC_IMG desktopImg={'right.gif'} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' ac-custom-image-slider-wrapper'}
      onClick={onClick}>

      <AC_IMG desktopImg={'left.gif'} />
    </div>
  );
}




class SimpleSlider extends Component {
  	
  constructor(props){
    super(props);
  	this.settings = {
      accessibility :false, 
      autoplay: true,
      dots: false,
      infinite: true,
      speed: 1000,
      autoplaySpeed: 4000,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      ...this.props.settings


    };
  }

  render() {


   

    return (
      <div className="Slider-wrapper">
      		 <Slider {...this.settings}  >
           {this.props.children}
          </Slider>
      </div>
    );
  }
}

export default SimpleSlider;
