/*
 * Main app config file
 *
*/

// export const APP_IMG_PATH = 'https://cdn.sabio.co.il/Bart/assets/img/';
// export const APP_NAME 		= 'ac';
// export const APP_VERSION 	= '1.0.0';
/*
 * API URL FOR ALL AJAX CALL
*/
export const API_URL = '/';