import React  , { Component } from 'react'
import { withRouter } from "react-router";
import LazyLoad from 'react-lazyload';
import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer/Footer'
import {  AC_TOPPART,
          AC_ROW,
          AC_INNER_PAGE_TITLE,
          AC_PRODUCT_BOX_ITEM } from '../../Components/UI/UI';
import { productsData } from './productsData';

import "./Products.css"
import "../innerPage.css"

class ProductSingle extends Component {
  
  state = {
    id: 'legumes'
  }

  getDataFromServer = () => {
    
  }

  componentDidMount  = () =>  {
   const { params } = this.props.match;
   
     this.setState({
      id :params.id
     })
  }


  render(){
    
    const { id } = this.state;
    const { data, title } = productsData[id];
    const desktopImg =     id + '/main.jpg';
    const mobileImg =     id + '/mob_main.jpg';
    const productsHTML = data.map( (o) => {
      return  <AC_PRODUCT_BOX_ITEM title={o.title} desktopImg={o.img} >
               {o.text}
              </AC_PRODUCT_BOX_ITEM>
    })
    return(
      <div className={'ac-page ac-page-inner ac-page-type-page ac-page-product ac-page-product-single ac-page-product-single-' +id }>        
        <Menu />
        <AC_TOPPART desktopImg={desktopImg} mobileImg={mobileImg} />
        <div class="ac-inner-page-container-component">
          <AC_ROW>
            <AC_INNER_PAGE_TITLE>
              {title}
            </AC_INNER_PAGE_TITLE>
          </AC_ROW>

         {productsHTML}

        </div>
        <LazyLoad height={200} once offset={400}>
          <Footer />
        </LazyLoad>
      </div>
    )
  }

}



export default withRouter(ProductSingle)