import React, { Component } from 'react';
import { Routes } from "./Routes/Routes";
import { isMobile , addFindSupport } from './Utility/Utility'
import './App.css' ;

import Loader  from './Components/Loader/Loader';
class App extends Component {

  constructor (){
  /*
    * The super keyword call functions on a parent object.
    * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/super
  */ 
  super(); // 

  this.state = { 
    timeToHide:500,
    isLoading : true,
   
  }
  /*
    * if find is undefined 
    * add the js find function 
  */
  if (!Array.prototype.find) {
    addFindSupport();
  }

  }
  /*
    * hideLoader
    * hide loader on page load
  */
  hideLoader = () => {
    this.setState({
      isLoading:false,

    })
  }

  componentDidMount () {
    
  }
  /*
   * render
   * @link https://reactjs.org/docs/render-props.html
  */
  render() {

    
   
    // add cssClass for css purpose
    let cssClass = 'desktopVersion ';
    if ( isMobile() ) {
        cssClass = ' MobileVersion ';
    }
    
    let html = <div className={'App ' + cssClass}>
                  { window.scrollTo(0, 0) }
                  <Routes />
              </div>

    if (this.state.isLoading === true){
      // else {
         html = <div  className={'App ' + this.state.currentBrowser }>
                 <div className="loader-main-wrapper">
                   <Loader />
                 </div>
                </div>
        window.setTimeout( ()=>{
          this.hideLoader()
        },this.state.timeToHide) 
      // }
      

    }

    return (html);
  }
}

export default App;
