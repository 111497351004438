import React  from 'react';
import { Link, NavLink } from 'react-router-dom'
import { getImgUrl, limitText , isMobile , getUrl } from "../../Utility/Utility"
import SimpleSlider from '../SimpleSlider/SimpleSlider';
import "./UI.css"


export const AC_Typography = (props) => {


  let elm  = ( props.elm ) ?  props.elm : "p" ;
  let html = <p>{props.children}</p>;
  if ( elm === 'h1' ) {
    html = <h1>{props.children}</h1>
  } else if ( elm === 'h2' ) {
    html = <h2>{props.children}</h2>
  } else if ( elm === 'h3' ) {
    html = <h3>{props.children}</h3>
  } else if ( elm === 'h4' ) {
    html = <h4>{props.children}</h4>
  } else if ( elm === 'h5' ) {
    html = <h5>{props.children}</h5>
  } else if ( elm === 'h6' ) {
    html = <h6>{props.children}</h6>
  }
  return(html) 

}

export const Ac_Divder = () => {
  return(
    <div className="ac-divder"></div>
  )
}

export const ResponsiveIframe =(props)=>{ 
 
  return(
    <div className="responisve-iframe">
        <iframe src={props.src} height="315" width="560" allowFullScreen="" frameBorder="0" title="youtube video">
        </iframe>
    </div>
  )

}

const AC_Children_Wrapper = ( props ) => {
  return(
    <div className={props.cssClass}>
      {props.children}
    </div>
  ) 
}


// wrappers 



export const AC_HomeSection = (props) => { 
  const  cssClass   = ( props.cssClass )   ? props.cssClass : "" ;
  const  containercssClass   = ( props.containercssClass )   ? props.containercssClass : "" ;
  const  id   = ( props.id )   ? props.id : "" ;
  const  isContainer   = ( props.isContainer )   ? props.isContainer : false;

  let html = props.children
  if ( isContainer  === true ) {
    html = 
      <AC_Container cssClass={containercssClass}>
       {props.children}
      </AC_Container>
  } 

  return(
    <section className={'ac-section-component ' + cssClass} id={id} >
      {html}
    </section>
  )
}

export const AC_INNER_PAGE_TITLE = (props) => {

  return(
    <div className="ac-inner-page-title-component-wrapper">
      <AC_Typography elm="h1">
        {props.children}
      </AC_Typography>
    </div>
  )
}

export const AC_ROW = (props) => {
  const  cssClass  = ( props.cssClass ) ?  props.cssClass : "" ;
  return(
    <AC_Children_Wrapper cssClass={'ac-row-component ' + cssClass} >
      {props.children}
    </AC_Children_Wrapper>
  )
}
export const AC_Container = (props) => {
  const  cssClass  = ( props.cssClass ) ?  props.cssClass : "" ;
  return (
    <AC_Children_Wrapper cssClass={'ac-container-component ' + cssClass}>
      {props.children}
    </AC_Children_Wrapper>
  )
}

export const AC_IMGAndText = props => {
  const  cssClass  = ( props.cssClass ) ?  props.cssClass : "" ;
  const  title   = ( props.title )   ? props.title : "" ;
  const  desktopImg  = ( props.desktopImg ) ?  props.desktopImg : "" ;
  const  link  = ( props.link ) ?  props.link : false ;
  let html = 
    <AC_Children_Wrapper cssClass={'ac-align-center ac-img-and-text-component ' + cssClass} >
      <AC_IMG desktopImg={desktopImg} />
      <AC_Typography elm="h3">
          {title}
      </AC_Typography>
      <AC_Typography>
        {props.children}
      </AC_Typography>
    </AC_Children_Wrapper>
  if ( link !==false ) {
    html = 
      <Link to={link}>
      <AC_Children_Wrapper cssClass={'ac-align-center ac-img-and-text-component ' + cssClass} >
        <AC_IMG desktopImg={desktopImg} />
        <AC_Typography elm="h3">
            {title}
        </AC_Typography>
        <AC_Typography>
          {props.children}
        </AC_Typography>
      </AC_Children_Wrapper>
    </Link>
  } 

  return(
    
    html
  )
}

export const Select =  ( props ) => {
  let name = props.name;
  let cssWrapperClass = 'form-input-wrapper input-' +  name + '-wrapper select-' +  name + '-wrapper' + props.cssClass;
  let cssInputClass = 'select-'+name +' '  ;
  let labelProp = props.label;
  let labelField  = '';
  let id = "ac-input-" + new Date().getTime();
  
  if ( labelProp !== undefined ) {
    cssWrapperClass += ' inputHasLabel '
    labelField = <label htmlFor={id}> {props.label} </label>
  }
  return (
    <div className={cssWrapperClass}>
      {labelField}
      <select onChange={props.change} name={props.name} className={cssInputClass}>
       {props.children};
      </select>
    </div>
  )
}



export const Input = ( props ) => {
  let name =  props.name;
  let labelProp =  props.label;
  let maxlength = props.maxlength;
  let id = props.id;

  let cssWrapperClass = 'form-input-wrapper input-' +  name + '-wrapper ' + props.inputCssClass;
  let cssInputClass = ' input-'+name +' '  ;
  
  let labelField  = '';
  // let id = "ac-input-" + new Date().getTime();
  
  if ( labelProp !== undefined ) {
    cssWrapperClass += ' inputHasLabel '
    labelField = <label htmlFor={id}> {props.label} </label>
  }
  let  html =  <div className={cssWrapperClass} >
                {labelField}
                <input id={id} data-validation-type={props.validationType} maxlength={props.maxlength} onClick={props.click} onChange={props.change} type={props.type} name={name} className={cssInputClass} placeholder={props.placeholder} value={props.val}/>              </div>
  if ( props.type === "hidden" ) {
    html =  <input  type={props.type} name={name} className={cssInputClass} placeholder={props.placeholder} value={props.val}/>
  }
  return (  html   );
};


export const Textarea = ( props ) => {
  let name =  props.name;
  let labelProp =  props.label;

  let cssWrapperClass = 'form-textarea-wrapper textarea-' +  name + '-wrapper ' + props.textareaCssClass;
  let cssInputClass = ' textarea-'+name +' '  ;
  
  let labelField  = '';
  let id = "ac-textarea-" + new Date().getTime();
  
  if ( labelProp !== undefined ) {
    cssWrapperClass += ' inputHasLabel '
    labelField = <label htmlFor={id}> {props.label} </label>
  }
  let  html =  
                <textarea  onClick={props.click} onChange={props.change} type={props.type} name={name} className={cssInputClass} placeholder={props.placeholder} value={props.val}/>         
  if ( props.type === "hidden" ) {
    html =  <textarea  type={props.type} name={name} className={cssInputClass} placeholder={props.placeholder} value={props.val}/>
  }
  return (  html   );
};

export const AC_DatesBoxItem = (props) => {
  const  title  = ( props.title ) ?  props.title : "" ;
  const  desktopImg  = ( props.desktopImg ) ?  props.desktopImg : "" ;

  return(
    <AC_Children_Wrapper cssClass={'ac-dates-box-item-component'}>
      <AC_ROW cssClass={'ac-flex ac-flexalign-center ac-justify-flexstart'}>
          <AC_IMG   desktopImg={desktopImg} 
              cssClass={'ac-dates-box-item-img-wrapper-component'}  />
          <AC_Children_Wrapper cssClass={'ac-dates-box-item-prod-data-wrapper'}>
            <AC_Typography elm="h3">
              {title}
            </AC_Typography>
            <AC_Typography>
              {props.color}
            </AC_Typography>
            <AC_Typography>
              {props.meta}
            </AC_Typography>
            <AC_Typography>
              {props.text}
            </AC_Typography>
          </AC_Children_Wrapper>
      </AC_ROW>
    </AC_Children_Wrapper>
  )
}

export const AC_PRODUCT_BOX_ITEM = (props ) => {
  const  title  = ( props.title ) ?  props.title : "" ;
  const  desktopImg  = ( props.desktopImg ) ?  props.desktopImg : "" ;

  return(
    <AC_Children_Wrapper cssClass={'ac-prod-box-item-component'}>
      <AC_ROW cssClass={'ac-flex ac-flexalign-center ac-justify-flexstart'}>
          <AC_IMG   desktopImg={desktopImg} 
              cssClass={'ac-prod-box-item-img-wrapper-component'}  />
          <AC_Children_Wrapper cssClass={'ac-prod-box-item-prod-data-wrapper'}>
            <AC_Typography elm="h2">
              {title}
            </AC_Typography>
            <AC_Typography>
              {props.children}
            </AC_Typography>
          </AC_Children_Wrapper>
      </AC_ROW>
    </AC_Children_Wrapper>
  )
}

export const AC_TOPPART = (props ) => {
  const  desktopImg  = ( props.desktopImg ) ?  props.desktopImg : "" ;
  const  mobileImg   = ( props.mobileImg )   ? props.mobileImg : "" ;
  const  title   = ( props.title )   ? props.title : "" ;
  const  cssClass   = ( props.cssClass )   ? props.cssClass : "" ;
  let html = null;
  let img =  <AC_IMG   desktopImg={desktopImg} 
                mobileImg={mobileImg}
                cssClass={'ac-width-100 ac-top-part-component-main-img-wrapper ' + cssClass}  />
  let bgImg = null;
  if ( title !=='' ) {
    bgImg = ( isMobile() ) ? getImgUrl() + '' + mobileImg : getImgUrl() + '' + desktopImg;
    img = null;
    html =  
      <AC_Container>
        <AC_ROW>
          <AC_Children_Wrapper cssClass={'ac-top-part-text-component ' + cssClass}>
            <AC_Typography elm='h1'>
              {title}
            </AC_Typography>
            <AC_Typography>
              {props.children}
            </AC_Typography>

          </AC_Children_Wrapper>
        </AC_ROW>
      </AC_Container>
  }

  return(
    <section className={'ac-top-part-component ' + cssClass} style={{backgroundImage: `url(${bgImg})`}}>
      {img}
      {html}
    </section>
  )
}

export const AC_IMG = (props) => {

  let desktopImg  = ( props.desktopImg ) ?  getImgUrl() + "" + props.desktopImg : "" ;
  let mobileImg   = ( props.mobileImg )   ?  getImgUrl() + "" + props.mobileImg : "" ;
 
  const cssClass  =  ( props.cssClass  !== undefined  )   ?  props.cssClass : "" ;
  
  const isExternalLink = ( props.isExternalLink !== undefined )   ? props.isExternalLink : false ;
  
  if ( isExternalLink ) {
    desktopImg = ( props.desktopImg ) ?  props.desktopImg : "" ;
    mobileImg = ( props.mobileImg )   ?  props.mobileImg: "" ;
  }
  let html = 
    <div className={'img-wrapper-component ' + cssClass}>
      <img onClick={props.click} src={desktopImg} alt=""/>
    </div>

  if ( props.mobileImg !== undefined ) {
    if ( isMobile() ) {
      html = 
          <div className={'img-wrapper-component ' + cssClass}>
            <img onClick={props.click} className="show-on-mobile" src={mobileImg} alt=""/>
          </div>

    }
  }
  return (html)
}


export const AC_Logo = () => {
  return(
    <div className="ac-logo-wrapper-component">
     <NavLink to="/" aria-label="Logo mial">
        <AC_IMG desktopImg={"logo.png"}   />
      </NavLink>
    </div>
  )
}

export const AC_Logo2 = () => {
  return(
    <div className="ac-logo-left-wrapper-component">
      <NavLink to="/" aria-label="mial impex 1986 ltd">
        <AC_IMG desktopImg={"old-logo.png"}   mobileImg={"/mobile/mob-logo.png"}/>
      </NavLink>
    </div>
  )
}

export const AC_Link = (props) => {
  const href = props.href;
  const text = props.text;
  const cssClass = props.cssClass;
  return(
    <div className="ac-wrapper-link">
      <a href={href} target="_blank" className={cssClass}>
         {text}
      </a>
    </div>
  )
}


export const AC_Paragraph = (props) => {
  const text = props.text;
  const className = props.className;
  return(
    
      <p className={className}>{text}</p>
   
  )
}

export const AC_Logo3 = () => {
  return(
    <div className="ac-logo-left-wrapper-component">
      <NavLink to="/" aria-label="mial impex 1986 ltd">
        <AC_IMG desktopImg={"logo3.png"}   />
      </NavLink>
    </div>
  )
}

export const AC_nav_icon = (props) => {
  const cssClass = props.cssClass;
  return(
    <div className="ac-nav-icon-component" onClick={props.click}>
      
        <AC_IMG mobileImg={"/mobile/nav-icon.png"}   />
      
    </div>
  )
}

export const AC_close_icon = (props) => {
  const cssClass = props.cssClass;
  return(
    <div className="ac-close-icon-component" onClick={props.click}>
      
        <AC_Paragraph  className="ac-close-navigator" text="+"/>
      
    </div>
  )
}



