import React  , { Component } from 'react'
import { withRouter } from "react-router";
import { NavLink } from 'react-router-dom'

import axios from "axios" 
import LazyLoad from 'react-lazyload';

import Menu from '../../Components/Menu/Menu'
import Footer from '../../Components/Footer/Footer'
import { AC_TOPPART } from '../../Components/UI/UI';
import Loader  from '../../Components/Loader/Loader';

import { getPosts } from '../../Utility/Utility';

  
import "./Products.css"




class Products extends Component {
  state = {
    
  }

  getDataFromServer = () => {
    
  }
  componentDidMount  = () =>  {
   
  }


  render(){
  
    return(
      <div className="ac-page ac-page-inner ac-page-type-page ac-page-product">        
        <Menu />
        <AC_TOPPART />
        <LazyLoad height={200} once offset={400}>
          <Footer />
        </LazyLoad>
      </div>
    )
  }

}



export default withRouter(Products)