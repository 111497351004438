import React, { Component } from "react" 
import { NavLink } from 'react-router-dom';
import {getUrl} from '../../Utility/Utility';

import { AC_Logo, AC_Logo2, AC_nav_icon, AC_close_icon} from "../UI/UI";

import { animateScroll as scroll } from 'react-scroll'



import "./Menu.css"
const handleScroll = (event , id) => {

  const elm = document.getElementById(id);
  
  if ( elm !==null) {
    // alert('d');
    event.preventDefault();
    scroll.scrollTo(elm.offsetTop);

  }

}


const changeLanguage = (event ) => {

  const url = getUrl();

    // alert('url: ' + url);
    event.preventDefault();
    window.location.href = url + 'en/';
}




class Menu extends Component {
  state = {
    isOpen : false
  }

  setMenuState = () => {

    this.setState({
      isOpen : !this.state.isOpen,
      
    })
  } 

 

  render(){
 
    let cssClass ="ac-close-menu";

    if ( this.state.isOpen === true ) {
      cssClass = "ac-open-menu";
    }

    let menuHTML = 
      <div className={'ac-menu-menu ' + cssClass}>
          <AC_close_icon click={this.setMenuState}/>
          <ul className="ac-flex ac-alignitems-start ac-justify-flexstart">
            <li>
              <NavLink to="/">בית</NavLink>
              </li>
            <li>
              <NavLink to="/#products" onClick={(event) => {
                handleScroll(event, 'products')
              }}>המוצרים שלנו</NavLink>
            </li>
            <li>
              <NavLink to="/Dates">התמרים שלנו</NavLink>
            </li>
            <li>
              <NavLink to="/#about-us" onClick={(event) => {
                handleScroll(event, 'about-us')
              }}>קצת עלינו</NavLink>
            </li>
            <li>
            <NavLink to="/#contact" onClick={(event) => {
                handleScroll(event, 'contact')
              }}>צור קשר</NavLink>
            </li>
          </ul>
      </div>


      let LangHTML = <div className="ac-language">
              <a href="https://mialimpex.com/en/" aria-label="go to en lang">EN</a>
          </div>

    return(
      <div className="ac-menu-component menu-outer-wrapper ac-width-100 ac-flex ac-flexalign-center ac-justify-center">
        <AC_nav_icon click={this.setMenuState}/>
        {menuHTML}
        <AC_Logo />
        <AC_Logo2 />
        {LangHTML}
        
      </div>
    )
  }
}


export default Menu