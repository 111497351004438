import React  from 'react';
import { Input , Select  } from "../Components/UI/UI" 

import { API_HOST } from '../Config/Config'
import Http from "../Axios/Axios"
import axios from 'axios';



/*
 * get Url 
 *  
 * get Client url 
 * 
 * return string client url 
*/
export const getUrl = () => { 
	
	const host 	= window.location.host;
	const protocol = window.location.protocol;
	const url = protocol + "//" + host   + '/';
	// const url = protocol + "//" + host   ;
	return url;
}

/*
 * getImgUrl 
 * get img path
 * return string client img path URL
*/
export const getImgUrl = () => { 
	// const host 	= window.location.host;
	// const protocol = window.location.protocol;

	const url = 'https://mialimpex.com/assets/img/';
	
	// const url = getUrl() + '/assets/img/';
	return url;
}


/*
 * limitText 
 * 
 * @param string string  text
 * @param int length = the limit of the text 
 * return limit Text string 
*/
export const limitText = (string , length = 40 ) => {


	let trimmedString = string.substring(0, length);
	let dots = "";
	if ( string.length > length ) {
		dots =  "...";
	}
	
	return trimmedString + "" + dots;

}
/*
 * isMobile 
 * 
 * return Boolyen true on mobile  
*/
export const isMobile = () => {
	let isMobile = false;
	const windowW = window.innerWidth ;

	if ( windowW < 1000 ) {
		isMobile = true;
	}
	return isMobile
}
/*
 * handleError 
 * 
 * This handle all the ajax error in the app 
 * return err   
*/
export const handleError = (e) => {
 console.log(e);
}

/**
 * handleInputChange 
 * main input  ajax error
*/
export const handleInputChange = (event) => {
	const target = event.target;
	const value = target.type === 'checkbox' ? target.checked : target.value;
	const name = target.name;
	return {
		name,
		value
	}
}


// validation 

export const simpleValidation = (o) => {
    if (o.val === '') {

        return false;
    } else {
        return true;
    }
}

// regx validation 
export const  regxValidation = ( o ) => {
    if ( o.val === '' ) {
        return false;
    } else {
        if (o.expression.test(o.val)) {
            return true;
        } else {
            return false;
        }
    }
}
/*
 * getPosts 
 * 
 * getPosts from the API
 * 
 * This make GET /  API_URL  / route
 * @param string route  
 * return promise   
*/
export const  getPosts = async (route, params = {} ) => {
 	try {  
        const posts = await Http.get(route, {
        	params : params
        });
        return posts.data;
  	} catch (e) {
	    handleError(e);
  	}
}

/*
 * getPostsById 
 * 
 * getPostsById from the API
 * 
 * This make GET /API_URL/posts/:id
 * @param params Object 
 * if you want too add param to the request  
 * return promise    
*/
export const  getPostsById = async (id , params = {}) => {
 	try {  
        const post =  await Http.get('/posts/' + id, {
        	params : params
        } );
  	 	return post.data;
  	} catch (e) {
	    handleError(e);
  	}
}

/*
 * getPostComments 
 * 
 * getPostComments from the API
 * 
 * This make GET /API_URL/comments?post=:id
 * @param params Object 
 * if you want too add param to the request  
 * return promise    
*/

export const  getPostComments = async ( params = {}) => {
	try {  
        const post =  await Http.get('/comments/' , {
        	params : params
        } );
  	 	return post.data;
  	} catch (e) {
	    handleError(e);
  	}
}





/*
 * Add find if .find() is undefined  
 * @link // https://tc39.github.io/ecma262/#sec-array.prototype.find
*/
export const addFindSupport  = () => {
	
	Object.defineProperty(Array.prototype, 'find', {
		value: function(predicate) {
		// 1. Let O be ? ToObject(this value).
		if (this == null) {
		  throw new TypeError('"this" is null or not defined');
		}

		var o = Object(this);

		// 2. Let len be ? ToLength(? Get(O, "length")).
		var len = o.length >>> 0;

		// 3. If IsCallable(predicate) is false, throw a TypeError exception.
		if (typeof predicate !== 'function') {
		  throw new TypeError('predicate must be a function');
		}

		// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
		var thisArg = arguments[1];

		// 5. Let k be 0.
		var k = 0;

		// 6. Repeat, while k < len
		while (k < len) {
		  // a. Let Pk be ! ToString(k).
		  // b. Let kValue be ? Get(O, Pk).
		  // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
		  // d. If testResult is true, return kValue.
		  var kValue = o[k];
		  if (predicate.call(thisArg, kValue, k, o)) {
		    return kValue;
		  }
		  // e. Increase k by 1.
		  k++;
		}

		// 7. Return undefined.
		return undefined;
		}
	});
	
}

