import React from "react" 
import { Link } from 'react-router-dom'

import { getImgUrl } from '../../Utility/Utility'
import {  Img  } from '../../Components/UI/UI'

import "./Footer.css"

const Footer = () => { 
  return(
    <div className="Footer-outer-wrapper">
      	<div className="container">
	       <div className="row">
	       		<a target="_blank" href="">כל הזכויות שמורות למיאל אימפקס (1986) בע"מ.</a> 
	       		<a target="_blank" href="">עיצוב אתר: studio roee shlomi</a> 
	       </div>
      	</div>
    </div>
  )
}

export default Footer