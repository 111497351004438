import React from 'react'
import { Route ,Switch } from 'react-router-dom'
// pages
import Home from '../Pages/Home/Home';
import Products from '../Pages/Products/Products';
import ProductSingle from '../Pages/Products/‏‏ProductSingle';
import Dates from '../Pages/Dates/Dates';

export const Routes = () => (
	<Switch>
		
		<Route  path="/Products/Product/:id" component={ProductSingle} />
		<Route  path="/Products/" component={Products} />
		<Route  path="/Dates/" component={Dates} />
		<Route  path="/" component={Home} />
	</Switch>

);

